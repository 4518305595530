// General color:
$color-corporate: #3919ed;
$color-corporate-sunset-red: #e4605a;
$color-corporate-light: #f0eefe;
$color-complementary: #525252;
$color-alert: #eb6759;
$color-success: #34d999;
$color-cold: #38cdff;
$color-orange: #ff911f;
$color-background: #fcfcfc;
$color-gray: #505050;

// Color text:
$color-text: #505050;
$color-text-white: #fff;
$color-text-disabled: #d9d9d6;
$color-text-title: #505050;
$color-text-subtitle: #97999b;
$color-text-alert: $color-alert;
$color-text-success: $color-success;
$color-text-success-secondary: #286c17;
$color-text-warning: $color-orange;
$color-text-warning-secondary: #8f6700;
$color-text-link: $color-corporate;
$color-text-grey: #888888;

// Border color:
$color-border-grey-light: #D5D5D5;

// Extra colors:
$color-white: #fff;
$color-black: #000;
$color-black-10: rgba(0, 0, 0, 0.1);
$color-black-25: rgba(0, 0, 0, 0.25);
$color-black-50: rgba(0, 0, 0, 0.5);
$color-gray-light: #97999b;
$color-gray-extra-light: #c1c1c1;
$color-gray-extra-extra-light: #f5f5f5;
$color-gray-backgroung: #888888;
$color-orange-flat: #ff8273;
$color-red-flat: #0d0d0d;
$color-purple-flat: #8e2cff;
$color-warning: #f7e19a;
$color-warning-background: #fcf9da;
$color-error-background: #ffefed;
$color-success-background: #eefbeb;
$color-alert-ant: #ff4d4f;

